import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
	createMuiTheme,
	makeStyles,
	TextField,
	Grid,
	MenuItem,
	Divider,
	Typography,
	Dialog,
	InputAdornment,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/clear-field.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/ic-info.svg";
import { ReactComponent as RetryIcon } from "../../assets/icons/retry.svg";
import CircularProgress from "@mui/material/CircularProgress";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import Tooltip from "@mui/material/Tooltip";
import styles from "./TransactionList.module.css";
import { ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
	allowCheck,
	removePartnerTxn,
	resetPartnerTxn,
	addPartnerTxn,
	updatePartnerTxn,
	selectPaymentMethod,
	clearPartnerTxns,
	setMultipleCheckNotAllowed,
	togglePartnerOpener,
	resetCustomPartnerServiceFee,
	updatePartnerServiceFee,
	toggleServiceFeeRetryDialog,
} from "../../redux/modules/partnerList";
import {
	verifyActionCreator,
	clearValidation,
	resetValidated,
	setPaymentDetails,
} from "../../redux/modules/validation";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
//import banks from "../../constants/banks";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import Alert from "@material-ui/lab/Alert";
import BlockIcon from "@material-ui/icons/Block";
import moment from "moment";
import Box from "@material-ui/core/Box";
import CancelIcon from "@material-ui/icons/Cancel";
import dot from "dot-object";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
	enableCommit,
	enableBuyLoadCommit,
	toggleShowValidateExpiryModal,
} from "../../redux/modules/timeout";
import { barcodeScan } from "../../utils/barcode";
import HTTP from "../../helpers/ApiClient";
import cx from "classnames";
import FullPageLoader from "./Components/FullPageLoader";
import FullPageLoaderPercentage from "./Components/FullPageLoaderPercentage";
import { ErrorModal } from "../../components/Modal/ErrorModal";
import { SuccessModal } from "../../components/Modal/SuccessModal";
import StartTransactionIcon from "../../assets/images/changefunds/ic-changefund.png";
import _ from "lodash";
import { getCookie, storeCookie } from "../../utils/cookies";
import DialogModal from "../Dialog/Dialog";
import failedIcon from "../../assets/icons/ic-failed.svg";

import BuyLoadPaymentForm from "./PaymentForm/BuyLoadPaymentForm";
const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#0176C0",
		},
	},
});
import {
	getBanksAction,
	getPartnerDetailsAction,
	setBankList,
} from "../../redux/modules/banks";
import NoFunds from "./NoFunds";
import { isEmpty, pickBy } from "lodash";
import { setIsWalletSufficient } from "../../redux/modules/walletBalance";
import {
	setPaymentErrorCode,
	setCheckDuplicate,
	setDuplicateTransactionList,
} from "../../redux/modules/transaction";
import { formatter } from "./util/formatter";
import NumberField from "./Components/NumberField";
import { getSessionStorage, setSessionStorage } from "../../utils/storage";
import SSSContributionDetails from "./Components/SSSContributionDetails";
import SSSShortTermLoanDetails from "./Components/SSSShortTermLoanDetails";
import RetryDialog from "./Components/RetryDialog";
import { Skeleton } from "@mui/material";
import DuplicateErrorModal from "../Modal/DuplicateErrorModal";
import ExpiredValidateModal from "../SessionTimeout/ExpiredValidateModal";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
			width: "25ch",
		},
	},
	formControlLabel: {
		font: "normal normal normal 10px/16px Poppins-Regular",
		"& label": {
			font: "normal normal normal 10px/16px Poppins-Regular",
		},
	},
	formControlErrorLabel: {
		font: "normal normal normal 10px/16px Poppins-Regular",
		"& label": {
			font: "normal normal normal 10px/16px Poppins-Regular",
		},
		color: "#D93025",
		paddingLeft: "2.7em",
	},
	inputLabel: {
		font: " normal normal normal 12px/18px Poppins-Regular",
		color: "#3B3B3B",
	},
	toolTipMessage: {
		font: " normal normal normal 11px/12px Poppins-Regular",
		color: "white",
		marginInline: "3px",
	},
	inputLabelSmall: {
		font: " normal normal normal 10px/16px Poppins-Regular",
		color: "#3B3B3B",
	},
	inputText: {
		font: "normal normal normal 12px/18px Poppins-Regular",
		color: "#3B3B3B",
	},
	validateBtn: {
		"background-color": "#32AA4D",
		color: "#FFFFFF",
		"text-align": "left",
		font: "normal normal normal 12px/18px Poppins-Regular",
		"letter-spacing": "0px",
		float: "right",
	},
	successValidateIcon: {
		color: "#32AA4D",
	},
	errorValidateIcon: {
		color: "red",
	},
	inputWithValue: {
		"& > *": {
			borderColor: "#3B3B3B",
		},
	},
	inputDisabled: {
		"& > *": {
			backgroundColor: "rgb(243 243 243) !important",
		},
	},
	mobileNoAdorment: {
		marginRight: "0px",
		"& > *": {
			color: "#000000",
		},
	},
	clearIcon: {
		color: "black",
	},
}));

const GreenColorButton = withStyles(() => ({
	root: {
		width: "126px",
		height: "42px",
		color: "#FFFFFF",
		backgroundColor: "#32AA4D",
		"&:hover": {
			backgroundColor: "#138906",
		},
	},
}))(Button);

const WhiteColorButton = withStyles(() => ({
	root: {
		width: "126px",
		height: "42px",
		color: "#4B5051",
		backgroundColor: "#FFFFFF",
		border: "1px solid #CED4DA",
		"&:hover": {
			backgroundColor: "#FFFFFF",
		},
	},
}))(Button);

const dialogStyles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: "22px",
		color: theme.palette.grey[500],
	},
	dialogTitle: {
		font: "normal normal 600 18px/21px Wavehaus-SemiBold;",
	},
});

const DialogTitle = withStyles(dialogStyles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6" className={classes.dialogTitle}>
				{children}
			</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});
const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

const DatePicker = ({
	dateRule,
	field,
	classes,
	fieldErrors,
	transaction,
	isFieldInvalid,
	data,
	isValidated,
	failedTransactions,
	onVerifyInputChange,
}) => {
	const [date, setDate] = useState(null);

	return (
		<KeyboardDatePicker
			size="small"
			disableToolbar
			variant="outlined"
			inputVariant="outlined"
			style={{ width: "200px" }}
			format={
				dateRule !== undefined
					? dateRule[0].includes(":")
						? dateRule[0].split(":")[1]
						: dateRule[1]["format"]
					: "MM/DD/YYY"
			}
			id={Object.keys(field)[0]}
			label={
				<Typography
					className={`${
						field[Object.keys(field)[0]].label.length < 21
							? classes.inputLabel
							: classes.inputLabelSmall
					}`}
				>
					{field[Object.keys(field)[0]].label}
				</Typography>
			}
			value={date}
			inputValue={date?._i}
			error={
				(Object.keys(fieldErrors).length > 0 &&
					fieldErrors[transaction.transactionKey] &&
					fieldErrors[transaction.transactionKey].transactions[
						Object.keys(field)[0]
					] &&
					fieldErrors[transaction.transactionKey].transactions[
						Object.keys(field)[0]
					] !== "") ||
				(isFieldInvalid(data.code, transaction.transactionKey, field) &&
					isValidated)
			}
			helperText={
				Object.keys(fieldErrors).length > 0 &&
				fieldErrors[transaction.transactionKey] &&
				fieldErrors[transaction.transactionKey].transactions[
					Object.keys(field)[0]
				] &&
				fieldErrors[transaction.transactionKey].transactions[
					Object.keys(field)[0]
				] !== ""
					? fieldErrors[transaction.transactionKey].transactions[
							Object.keys(field)[0]
					  ]
					: isFieldInvalid(
							data.code,
							transaction.transactionKey,
							field
					  ) && isValidated
					? failedTransactions[data.code].find(
							(txn) =>
								txn.transactionKey ===
								transaction.transactionKey
					  ).errors[Object.keys(field)[0]][0].message
					: null
			}
			InputProps={{
				classes: {
					input: styles.textInput,
					root: `${
						// date
						classes.inputWithValue
						// : ""
					}`,
				},
			}}
			onChange={(value) => {
				onVerifyInputChange(
					data.code,
					transaction.transactionKey,
					value,
					Object.keys(field)[0],
					dateRule
				);
				setDate(value);
			}}
		/>
	);
};

function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue((value) => value + 1); // update state to force render
	// A function that increment 👆🏻 the previous state like here
	// is better than directly setting `setValue(value + 1)`
}

function TransactionList({
	showAlert,
	hasWallet,
	hasWalletBalance,
	banks,
	selectedPartners,
	isServiceFeeRetryDialogOpen,
	verifyActionCreator,
	toggleServiceFeeRetryDialog,
	successValidation,
	isValidating,
	clearValidation,
	resetValidated,
	resetCustomPartnerServiceFee,
	updatePartnerServiceFee,
	isValidated,
	partnerToAdd,
	allowCheck,
	partnerTransactions,
	removePartnerTxn,
	resetPartnerTxn,
	addPartnerTxn,
	updatePartnerTxn,
	selectPaymentMethod,
	selectedPaymentMethod,
	setPaymentDetails,
	failedTransactions,
	validTransactions,
	paymentDetails,
	clearPartnerTxns,
	setMultipleCheckNotAllowed,
	partnerOpener,
	togglePartnerOpener,
	isGenericError,
	notActiveError,
	enableCommit,
	enableBuyLoadCommit,
	toggleShowValidateExpiryModal,
	getBanksAction,
	setBankList,
	getPartnerDetailsAction,
	timeoutError,
	hasNoError,
	paymentErrorCode,
	validateMessageStatus,
	setIsWalletSufficient,
	setPaymentErrorCode,
	setCheckDuplicate,
	setDuplicateTransactionList,
}) {
	const classes = useStyles();
	const [selectedDate, setDate] = useState(null);
	//for FE Validation; listed all required only
	const [fieldErrors, setErrors] = useState({});
	const [paymentFieldErrors, setPaymentErrors] = useState({});
	const [showRemoveModal, setRemoveModal] = useState(false);
	const [showResetModal, setResetModal] = useState(false);
	const [showClearModal, setClearModal] = useState(false);
	const [txnToRemove, setTxnToRemove] = useState({
		code: null,
		txnKey: null,
	});
	const [privacyInfoCheck, setPrivacyInfoCheck] = useState({
		transparency: true,
		dataIntegrity: true,
		transparencyErrMsg: "",
		dataIntegrityErrMsg: "",
	});
	const [isRequestPartnerSFLoading, setRequestPartnerSFLoading] =
		useState(false);
	const [validInput, setValidInput] = useState(null);
	const [amountInput, setAmountInput] = useState(null);
	const [isClearing, setClearing] = useState(false);
	const [isCustomerDetailsCheck, setCustomerDetailsCheck] = useState(false);
	const [inquireArray, setInquireArray] = useState([]);
	const [showDialogModal, setDialogModal] = useState(false);
	const [
		showServiceFeeFailedDialogModal,
		setShowServiceFeeFailedDialogModal,
	] = useState(false);
	const [requestedPartnerServiceFee, setRequestPartnerServiceFee] =
		useState("");
	const [openExpireValidateModal, setOpenExpireValidateModal] =
		useState(false);
	const partnerFormAmounts = [
		"amount",
		"otherInfo.BillAmount",
		"otherInfo.TotalPayableAmount",
		"otherInfo.PremiumAmount",
		"otherInfo.LoanAmount",
		"otherInfo.WaterDue",
		"otherInfo.AssociationFee",
		"otherInfo.TotalAssessment",
		"otherInfo.ReconnectionFee",
	];
	const productPartnerUnavailableInfo =
		"This Product Partner is currently disabled. Please contact Bayad Helpdesk or your Bayad admin support to enable this product partner in the BIP Portal.";
	const SSS_CONTRIBUTION = ["SSS01", "SSSC1"];
	const SSS_SHORT_TERM_LOANS = ["SSS02", "SSSC2"];
	const productParnetNotActive = "Status of Partner is inactive";

	const handleClose = () => {
		setRemoveModal(false);
		setResetModal(false);
		setClearModal(false);
		setPaymentErrorCode({
			code: null,
			message: null,
			displayType: null,
			header: null,
		});
	};

	const checkSessionBanks = () => {
		let checkBankCode = _.chain(getSessionStorage("banks"))
			.findKey("bank_code")
			.isString()
			.value();
		let checkBankName = _.chain(getSessionStorage("banks"))
			.findKey("bank_name")
			.isString()
			.value();

		return checkBankCode && checkBankName;
	};

	useEffect(() => {
		try {
			if (!getSessionStorage("banks") && banks.length === 0) {
				getBanksAction();
			} else if (!getSessionStorage("banks") && banks.length > 0) {
				setBankList(banks);
				setSessionStorage("banks", banks);
			} else if (getSessionStorage("banks")) {
				if (checkSessionBanks()) {
					setBankList(getSessionStorage("banks"));
				} else {
					setBankList(banks);
					setSessionStorage("banks", banks);
				}
			}
		} catch (error) {
			setBankList(banks);
			setSessionStorage("banks", banks);
		}
	}, []);

	useEffect(() => {
		if (hasNoError) {
			initializeValidateExpiry();
			toggleShowValidateExpiryModal(true);
		} else {
			toggleShowValidateExpiryModal(false);
		}
	}, [hasNoError]);

	useEffect(() => {
		const handleUnload = (e) => {
			// Perform actions before the component unloads
			if (!checkSessionBanks()) setSessionStorage("banks", banks);
		};
		window.addEventListener("unload", handleUnload);
		return () => {
			window.removeEventListener("unload", handleUnload);
		};
	}, [banks]);

	useEffect(() => {
		//initialize date to null when no partners
		if (selectedPartners.length == 0) {
			setDate(null);
			setCustomerDetailsCheck(false);
		}
	}, [selectedPartners]);

	useEffect(() => {
		if (isClearing) {
			setClearing(false);
		}
	}, [isClearing]);

	useEffect(() => {
		if (isValidated && isEmpty(failedTransactions))
			setIsWalletSufficient(true);
	}, [failedTransactions, isValidated]);

	useEffect(() => {
		if (paymentErrorCode && paymentErrorCode?.code) {
			if (paymentErrorCode?.code !== "DUP_TRANS") {
				resetValidated();
			}
		}
	}, [paymentErrorCode]);

	const initializePaymentModes = () => {
		let concatinated = [];
		selectedPartners
			.map((partners) => {
				if (!isEmpty(partners)) {
					let paymentMethodParam = partners.parameters.verify.filter(
						(parameter) =>
							Object.keys(parameter)[0] === "paymentMethod"
					)[0].paymentMethod;
					return Object.keys(paymentMethodParam.rules)
						.filter((methods) => methods.includes("in:"))[0]
						.replace("in:", "")
						.split(",");
				}
			})
			.forEach((mode) => (concatinated = concatinated.concat(mode)));
		return Array.from(new Set(concatinated));
	};

	const onVerifyInputChange = (
		code,
		txnKey,
		event,
		dateField = null,
		dateRule = null
	) => {
		if (event !== null) {
			const amount_names = ["amount", "otherInfo.BillAmount"];
			if (
				event.target !== undefined &&
				amount_names.includes(event.target.name)
			) {
				const number = /^[0-9]*[.]{0,1}[0-9]*$/;

				if (
					number.test(event.target.value) ||
					event.target.value === ""
				) {
					setAmountInput(event.target.value);
				} else {
					event.target.value = amountInput;
				}
			}
		}

		const id = dateField
			? dateField
			: event.target.id !== undefined
			? event.target.id
			: event.target.name;
		const dateFormat = dateRule
			? dateRule[0].includes(":")
				? dateRule[0].split(":")[1]
				: dateRule[1]["format"]
			: "MM/dd/yyyy";
		let momentDate = null;
		if (event) {
			momentDate =
				event._f === "YYYY-YYYY" || event._f === "YYYY - YYYY"
					? event._i
					: moment(event).format(dateFormat);
		}
		const value = dateRule ? momentDate : event.target.value;

		if (code == "STICO" && id == "otherInfo.SchoolsCode") {
			//update check reminder depending on school code for STI
			getPartnerDetailsAction(value);
		}

		updatePartnerTxn({
			code: code,
			txnKey: txnKey,
			key: id,
			value: value,
		});

		if (Object.keys(validTransactions).length > 0) {
			resetValidated();
		}
	};

	const amountFields = ["otherInfo.CheckDetails.Amount"];

	const onPaymentDetailsChange = (event) => {
		let _v = event.target.value;
		const key = event.target.id ? event.target.id : event.target.name;
		setValidInput(event.target.value);

		if (amountFields.findIndex((v) => v == key) != -1) {
			_v = _v.replaceAll(",", ""); //remove commas if amount field
			_v = "" + +Number(_v).toFixed(2); //remove trailing 0s and period if applicable
		}
		let newPaymentDetails = {
			[key]: _v,
		};

		//set bank name
		//update > modify bank lookup from banks constants to state.banks
		if (key == "otherInfo.CheckDetails.BankCode") {
			const _currBank = banks.find((bank) => {
				return bank.bank_code == event.target.value;
			});

			newPaymentDetails["otherInfo.CheckDetails.BankName"] =
				_currBank.bank_name;
		}

		dot.object(newPaymentDetails);

		if (!paymentDetails.otherInfo) {
			setPaymentDetails(newPaymentDetails);
		} else {
			if (event.target.id === "otherInfo.initialCash") {
				setPaymentDetails({
					...paymentDetails,
					otherInfo: {
						...paymentDetails.otherInfo,
						CustomerDetails: {
							...paymentDetails.otherInfo.CustomerDetails,
							...newPaymentDetails.otherInfo.CustomerDetails,
						},
						initialCash: event.target.value,
					},
				});
			} else {
				setPaymentDetails({
					...paymentDetails,
					otherInfo: {
						...paymentDetails.otherInfo,
						CustomerDetails: {
							...paymentDetails.otherInfo.CustomerDetails,
							...newPaymentDetails.otherInfo.CustomerDetails,
						},
						CheckDetails: {
							...paymentDetails.otherInfo.CheckDetails,
							...newPaymentDetails.otherInfo.CheckDetails,
						},
					},
				});
			}
		}
		if (Object.keys(validTransactions).length > 0) {
			resetValidated();
		}
	};

	const handleCustomerDetailsCheck = (check, type) => {
		switch (type) {
			case "consent":
				setCustomerDetailsCheck(check);
				if (!check) {
					clearCustomerDetails();
				} else {
					setPaymentDetails({
						...paymentDetails,
						otherInfo: {
							...paymentDetails.otherInfo,
							CustomerDetails: {},
						},
					});
				}
				break;
			case "transparency":
			case "dataIntegrity":
				setPrivacyInfoCheck({ ...privacyInfoCheck, [type]: check });
				break;
			default:
				setCustomerDetailsCheck(check);
				setPrivacyInfoCheck({
					transparency: true,
					dataIntegrity: true,
					transparencyErrMsg: "",
					dataIntegrityErrMsg: "",
				});
				break;
		}
	};

	const clearCustomerDetails = () => {
		let paymentDetailsErrors = paymentFieldErrors;
		document
			.getElementsByName("customerInformationModeForm")
			.forEach((form) => form.reset());
		setPaymentDetails({
			...paymentDetails,
			otherInfo: {
				...paymentDetails.otherInfo,
				CustomerDetails: {},
			},
		});

		let customerInfoFields = [
			"firstName",
			"middleInitial",
			"lastName",
			"emailAddress",
			"mobileNumber",
			"address",
		];
		if (paymentFieldErrors) {
			customerInfoFields.forEach((field) => {
				if (
					Object.keys(paymentFieldErrors).includes(
						"otherInfo.CustomerDetails." + field
					)
				) {
					delete paymentDetailsErrors[
						"otherInfo.CustomerDetails." + field
					];
				}
			});
			setPaymentErrors({ ...paymentDetailsErrors });
		}
	};

	const handleOnCloseExpireValidationModal = () => {
		setOpenExpireValidateModal(false);
	};

	const initializeValidateExpiry = () => {
		setOpenExpireValidateModal(true);
	};

	const handleDateChange = (date) => {
		setDate(date);
		if (!paymentDetails.otherInfo) {
			setPaymentDetails({
				CheckDetails: {
					CheckDate: date ? moment(date).format("MM/DD/YYYY") : "", //fix : add handling for date value ""
				},
			});
		} else {
			setPaymentDetails({
				...paymentDetails,
				otherInfo: {
					...paymentDetails.otherInfo,
					CheckDetails: {
						...paymentDetails.otherInfo.CheckDetails,
						CheckDate: date
							? moment(date).format("MM/DD/YYYY")
							: "", //fix : add handling for date value ""
					},
				},
			});
		}
	};

	const handleVerify = () => {
		setCheckDuplicate(true);
		setDuplicateTransactionList([]);
		setPaymentErrorCode({
			code: null,
			message: null,
			displayType: null,
			header: null,
		});
		let formErrors = {};
		let paymentDetailsErrors = {};
		clearValidation({ exclude: { additionalCash: true } });
		Object.keys(partnerTransactions).forEach((code) => {
			const verifyPartner = selectedPartners.find(
				(partner) => partner.code === code
			);
			if (verifyPartner) {
				const partnerIndex = selectedPartners.findIndex(
					(partner) => partner.code === code
				);
				let partnerFields =
					selectedPartners[partnerIndex].parameters.verify;
				partnerTransactions[code].forEach((txn) => {
					partnerFields
						.filter(
							(field) =>
								Object.keys(field)[0] !== "paymentMethod" &&
								Object.keys(field)[0] !== "otherCharges" &&
								!Object.keys(field)[0].startsWith(
									"otherInfo.CheckDetails"
								)
						)
						.forEach((partnerField) => {
							let partnerFieldKey = Object.keys(partnerField)[0];

							//check inquire array if the user hasn't inquired before validating
							if (
								inquireArray.findIndex((val) => {
									return (
										val.transactionKey ==
											txn.transactionKey &&
										val.prop ==
											Object.keys(partnerField)[0] &&
										val.shouldInquire == true
									);
								}) != -1
							) {
								formErrors[txn.transactionKey] = {
									hasError: true,
									transactions: {
										[partnerFieldKey]:
											"Please inquire reference no before validating",
									},
									payment: {},
								};
							} else {
								Object.keys(
									partnerField[partnerFieldKey].rules
								).forEach((rule) => {
									if (rule.includes("required")) {
										let ruleCondition = rule.includes(
											"required_if"
										)
											? rule.split(":")[1]
											: "";
										let additionalCondition =
											ruleCondition !== ""
												? txn[
														ruleCondition.split(
															","
														)[0]
												  ] ===
												  ruleCondition.split(",")[1]
												: true;

										if (
											additionalCondition &&
											(txn[partnerFieldKey] ===
												undefined ||
												txn[partnerFieldKey] === "")
										) {
											let txnErrors = formErrors[
												txn.transactionKey
											]
												? formErrors[txn.transactionKey]
														.transactions
												: {};
											formErrors[txn.transactionKey] = {
												hasError: true,
												transactions: {
													...txnErrors,
													[partnerFieldKey]:
														partnerField[
															partnerFieldKey
														].rules[rule].message,
												},
												payment: {},
											};
										}
									}
								});
							}
						});
				});
			}
		});

		//START : CUSTOMER DETAILS VALIDATE
		if (paymentDetails && paymentDetails?.otherInfo?.CustomerDetails) {
			//validate last name
			if (
				!paymentDetails.otherInfo.CustomerDetails.lastName &&
				isCustomerDetailsCheck
			) {
				paymentDetailsErrors = {
					...paymentDetailsErrors,
					"otherInfo.CustomerDetails.lastName":
						"Please provide the last name.",
				};
			}
			if (
				paymentDetails.otherInfo.CustomerDetails.lastName &&
				!formatter.charMax100(
					paymentDetails.otherInfo.CustomerDetails.lastName
				)
			) {
				paymentDetailsErrors = {
					...paymentDetailsErrors,
					"otherInfo.CustomerDetails.lastName":
						"The max. no of characters is 100.",
				};
			}
			//validate first name
			if (
				!paymentDetails.otherInfo.CustomerDetails.firstName &&
				isCustomerDetailsCheck
			) {
				paymentDetailsErrors = {
					...paymentDetailsErrors,
					"otherInfo.CustomerDetails.firstName":
						"Please provide the first name.",
				};
			}
			if (
				paymentDetails.otherInfo.CustomerDetails.firstName &&
				!formatter.charMax100(
					paymentDetails.otherInfo.CustomerDetails.firstName
				)
			) {
				paymentDetailsErrors = {
					...paymentDetailsErrors,
					"otherInfo.CustomerDetails.firstName":
						"The max. no of characters is 100.",
				};
			}
			//validate middle initial
			if (
				!paymentDetails.otherInfo.CustomerDetails.middleInitial &&
				isCustomerDetailsCheck
			) {
				paymentDetailsErrors = {
					...paymentDetailsErrors,
					"otherInfo.CustomerDetails.middleInitial":
						"Please provide the middle initial.",
				};
			}
			if (
				paymentDetails.otherInfo.CustomerDetails.middleInitial &&
				!formatter.charMax100(
					paymentDetails.otherInfo.CustomerDetails.middleInitial
				)
			) {
				paymentDetailsErrors = {
					...paymentDetailsErrors,
					"otherInfo.CustomerDetails.middleInitial":
						"The max. no of characters is 100.",
				};
			}
			//validate email address
			if (
				!paymentDetails.otherInfo.CustomerDetails.emailAddress &&
				isCustomerDetailsCheck
			) {
				paymentDetailsErrors = {
					...paymentDetailsErrors,
					"otherInfo.CustomerDetails.emailAddress":
						"Please provide the email address.",
				};
			}
			if (
				paymentDetails.otherInfo.CustomerDetails.emailAddress &&
				!formatter.emailAddress(
					paymentDetails.otherInfo.CustomerDetails.emailAddress
				)
			) {
				paymentDetailsErrors = {
					...paymentDetailsErrors,
					"otherInfo.CustomerDetails.emailAddress":
						"The email address should follow this format ex. juandelacruz@bayad.com",
				};
			}
			//validate mobile number
			if (
				!paymentDetails.otherInfo.CustomerDetails.mobileNumber &&
				isCustomerDetailsCheck
			) {
				paymentDetailsErrors = {
					...paymentDetailsErrors,
					"otherInfo.CustomerDetails.mobileNumber":
						"Please provide the mobile number.",
				};
			}
			if (paymentDetails.otherInfo.CustomerDetails.mobileNumber) {
				let mobileNumber =
					paymentDetails.otherInfo.CustomerDetails.mobileNumber;
				if (mobileNumber.length < 10 || mobileNumber.length > 10) {
					paymentDetailsErrors = {
						...paymentDetailsErrors,
						"otherInfo.CustomerDetails.mobileNumber":
							"The mobile should have a max. of 10 digits",
					};
				}
			}
		}
		//END : CUSTOMER DETAILS VALIDATE

		switch (selectedPaymentMethod) {
			case "CASH": {
				if (paymentDetails && paymentDetails.otherInfo) {
					if (
						!paymentDetails.otherInfo.initialCash ||
						isNaN(paymentDetails.otherInfo.initialCash)
					) {
						paymentDetailsErrors = {
							...paymentDetailsErrors,
							"otherInfo.initialCash":
								"Please provide the initial cash received.",
						};
					} else {
						console.log(
							"No errors",
							parseFloat(paymentDetails.otherInfo.initialCash)
						);
					}
				} else {
					paymentDetailsErrors = {
						...paymentDetailsErrors,
						"otherInfo.initialCash":
							"Please provide the initial cash received.",
					};
				}

				break;
			}
			case "CHECK": {
				// validation from partnerInfo response
				if (paymentDetails?.otherInfo?.CheckDetails) {
					let partnerCode = "";
					Object.keys(partnerTransactions).forEach((code) => {
						if (partnerTransactions[code].length > 0)
							partnerCode = code;
					});
					let total_bill_amount = 0;
					partnerTransactions[partnerCode].forEach((txn) => {
						total_bill_amount =
							total_bill_amount + parseFloat(txn.amount);
					});

					if (
						parseFloat(
							paymentDetails.otherInfo.CheckDetails.Amount || 0
						).toFixed(2) !==
						parseFloat(total_bill_amount || 0).toFixed(2)
					) {
						let errMsg =
							"The check amount must be equal to the amount.";
						if (partnerTransactions[partnerCode].length > 1)
							errMsg =
								"The total sum amount for multiple payments should be equal to check payment amount.";
						paymentDetailsErrors = {
							...paymentDetailsErrors,
							"otherInfo.CheckDetails.Amount": errMsg,
						};
					}

					if (!paymentDetails.otherInfo.CheckDetails.Amount) {
						paymentDetailsErrors = {
							...paymentDetailsErrors,
							"otherInfo.CheckDetails.Amount":
								"Please provide the check amount.",
						};
					}
				} else {
					paymentDetailsErrors = {
						...paymentDetailsErrors,
						// START : REMOVED > Field rule REQUIRED validation will be on API 3.0
						// "otherInfo.CheckDetails.ContactNumber":
						// 	"Please provide the contact number.",
						// "otherInfo.CheckDetails.CheckDate":
						// 	"Please provide the check date.",
						// "otherInfo.CheckDetails.CheckNo":
						// 	"Please provide the check number.",
						// "otherInfo.CheckDetails.BankBranch":
						// 	"Please provide the bank branch.",
						// "otherInfo.CheckDetails.BankCode":
						// 	"Please provide the bank name.",
						// END : REMOVED > Field rule REQUIRED validation will be on API 3.0
						"otherInfo.CheckDetails.Amount":
							"Please provide the check amount.",
					};
				}
				break;
			}
			case "CASH+CHECK": {
				break;
			}
			default: {
				break;
			}
		}

		setErrors(formErrors);
		setPaymentErrors(paymentDetailsErrors);

		if (
			Object.keys(formErrors).length === 0 &&
			Object.keys(paymentDetailsErrors).length === 0
		) {
			console.log("inside>>> test data to enable commit>>>");
			enableBuyLoadCommit();
			// enableCommit();
			// verifyActionCreator(partnerTransactions).then(() => {
			// });
		}
	};

	const isServiceFeeFetchSuccessful = (partnerCode) => {
		const list = getCookie("service_fee");

		const foundItem = list.find((item) => item.code === partnerCode);
		if (
			foundItem.isRequestSuccessful !== undefined &&
			foundItem.isRequestSuccessful !== null &&
			foundItem.isRequestSuccessful !== ""
		) {
			return foundItem.isRequestSuccessful;
		}
	};

	function updateCustomPartnerServiceFee(partnerCode) {
		const midnight = new Date();
		midnight.setHours(23, 59, 59, 0);
		var list;

		// if cached list is already exist modify the list
		if (getCookie("service_fee")) {
			list = getCookie("service_fee");

			list = list.map((item) => {
				if (item.code === partnerCode) {
					return {
						...item,
						otherCharges: 0, // Example update value, change as needed
						isRegular: 0, // Assuming 1 represents true/yes
						isRequestSuccessful: true,
					};
				}
				return item;
			});
		} else {
			// if cached list is not yet exist save directly
			list = [
				{
					code: partnerCode,
					otherCharges: 0,
					isRegular: 0,
					isRequestSuccessful: true,
				},
			];
		}

		storeCookie("service_fee", list, midnight);
	}

	const forceUpdate = useForceUpdate();

	async function RequestPartnerServiceFee(partnerCode, transactionKey) {
		setRequestPartnerServiceFee(transactionKey);
		setRequestPartnerSFLoading(true);
		let otherCharges = 0.0;

		await HTTP.get(`/v2/billers/${partnerCode}/fees`)
			.then((response) => {
				otherCharges = response.data.data.otherCharges;
				updatePartnerServiceFee({ partnerCode, otherCharges });
				forceUpdate();
			})
			.catch((error) => {
				if (error.response.status == 422) {
					updateCustomPartnerServiceFee(partnerCode);
					forceUpdate();
				} else {
					setShowServiceFeeFailedDialogModal(true);
				}
			});

		setRequestPartnerSFLoading(false);
	}

	const handleClick = (code) => {
		togglePartnerOpener({ code: code });
	};

	const closeAlert = () => {
		resetValidated();
		setErrors({});
		setPaymentErrors({});
		setPaymentErrorCode({
			code: null,
			message: null,
			displayType: null,
			header: null,
		});
	};

	const resetPaymentErrorCode = () => {
		setPaymentErrorCode({
			code: null,
			message: null,
			displayType: null,
			header: null,
		});
	};

	const handleClear = () => {
		document
			.getElementsByName("billsPaymentForm")
			.forEach((form) => form.reset());
		document
			.getElementsByName("billsPaymentModeForm")
			.forEach((form) => form.reset());
		document
			.getElementsByName("customerInformationModeForm")
			.forEach((form) => form.reset());
		setErrors({});
		setPaymentErrors({});
		setPaymentDetails({});
		clearValidation();
		clearPartnerTxns();
		setClearModal(false);
		setClearing(true);
		handleCustomerDetailsCheck(false, "all");
	};

	const onPaymentModeChange = (event) => {
		let addedPartnerList = [];
		selectedPartners.forEach((partner) => {
			if (!isEmpty(partner)) addedPartnerList.push(partner.code);
		});
		if (addedPartnerList.length > 1 && event.target.value === "CHECK") {
			setMultipleCheckNotAllowed();
		} else {
			selectPaymentMethod({ mode: event.target.value });
			setDate(null);
		}
		setErrors({});
		setPaymentErrors({});
		if (paymentDetails?.otherInfo?.CustomerDetails) {
			setPaymentDetails({
				...paymentDetails,
				otherInfo: {
					CustomerDetails: {
						...paymentDetails.otherInfo.CustomerDetails,
					},
				},
			});
		} else {
			setPaymentDetails({});
		}

		if (Object.keys(successValidation).length > 0) {
			resetValidated();
		}
	};

	const addTransaction = (partnerCode) => {
		addPartnerTxn({ code: partnerCode });
		clearValidation();
		resetCustomPartnerServiceFee();
	};

	// Checker if the group of field is empty
	const valueChecker = (code, txnKey) => {
		let index = partnerTransactions[code].findIndex(
			(transaction) => transaction.transactionKey === txnKey
		);

		const partnerValues = partnerTransactions[code][index];
		for (const key in partnerValues) {
			if (
				key !== "transactionKey" &&
				key !== "otherCharges" &&
				key !== "isValidated"
			) {
				if (
					partnerValues[key] !== "" &&
					partnerValues[key] !== ".00" &&
					partnerValues[key] !== null &&
					partnerValues[key] !== "0" &&
					partnerValues[key] !== "0.00" &&
					partnerValues[key] !== "0.000"
				) {
					return false;
				}
			}
		}
		return true;
	};

	// click remove handler
	const clickRemove = (code, txnKey) => {
		valueChecker(code, txnKey);

		const isFieldEmpty = valueChecker(code, txnKey);

		if (!isFieldEmpty) {
			setTxnToRemove({
				code: code,
				txnKey: txnKey,
			});
			setRemoveModal(true);
		} else {
			removeTransaction(code, txnKey);
		}
	};

	const clickClear = () => {
		setClearModal(true);
	};

	const handleRemoveAll = () => {
		var isFieldsEmpty = true;
		// Loop checking all the field if it is empty
		for (const key in partnerTransactions) {
			if (partnerTransactions[key].length !== 0) {
				partnerTransactions[key].forEach((formValues) => {
					for (const key in formValues) {
						if (
							key !== "transactionKey" &&
							key !== "otherCharges"
						) {
							if (
								formValues[key] !== "" &&
								formValues[key] !== ".00" &&
								formValues[key] !== null &&
								formValues[key] !== "0" &&
								formValues[key] !== "0.00" &&
								formValues[key] !== "0.000"
							) {
								isFieldsEmpty = false;
								return;
							}
						}
					}
					if (!isFieldsEmpty) {
						return;
					}
				});
			}
		}

		if (isFieldsEmpty == false) {
			console.log("inside handle remove all if condition>>>");
			setResetModal(true);
		} else {
			console.log("inside handle remove all else condition>>>");
			resetTransaction();
		}
	};

	const isRegular = (partnerCode) => {
		const list = getCookie("service_fee");
		const foundItem = list.find((item) => item.code === partnerCode);

		if (foundItem.isRegular == 1) {
			return true;
		} else {
			return false;
		}
	};

	const getTransactionObject = (partnerCode, TransactionKey) => {
		return partnerTransactions[partnerCode].find(
			(txn) => txn.transactionKey === TransactionKey
		);
	};

	// Checking if to show service fee value or "TBD"
	const isSFIsAllowedToDisplay = (partnerCode) => {
		if (isRegular(partnerCode)) {
			return true;
		} else {
			if (isValidated) {
				return true;
			} else {
				return false;
			}
		}
	};

	const resetTransaction = () => {
		setResetModal(false);
		resetPartnerTxn();
		// resetter
		setErrors({});
		setTxnToRemove({
			code: null,
			txnKey: null,
		});
		clearValidation();
		initializePaymentModes();
		setCustomerDetailsCheck(false);
		setPaymentErrors({});
		resetPaymentErrorCode();
	};

	const removeTransaction = (partnerCode, transactionCode) => {
		let indexToRemove = partnerTransactions[partnerCode].findIndex(
			(transaction) => transaction.transactionKey === transactionCode
		);

		setRemoveModal(false);

		removePartnerTxn({
			code: partnerCode,
			indexToRemove: indexToRemove,
		});
		setErrors({});
		setTxnToRemove({
			code: null,
			txnKey: null,
		});
		clearValidation();
		initializePaymentModes();
		let getPartnerCodes = [];
		selectedPartners.forEach((partner) => {
			if (!isEmpty(partner)) getPartnerCodes.push(partner.code);
		});

		if (
			getPartnerCodes.length === 1 &&
			partnerTransactions[partnerCode].length === 1
		) {
			resetTransaction();
		}
		resetCustomPartnerServiceFee();
	};

	const getTBDSpiel = (partnerCode) => {
		const option_one =
			"Cannot show the convenience fee for this partner. Kindly click the Validate button.";
		const option_two =
			"Failed to retrieve convenience fee. Kindly click the refresh button.";
		const serviceFeeStatus = isServiceFeeFetchSuccessful(partnerCode);
		if (serviceFeeStatus == true) {
			console.log("true");
			return option_one;
		} else {
			return option_two;
		}
	};

	const isFieldInvalid = (code, txnKey, field) => {
		return (
			Object.keys(failedTransactions).length > 0 &&
			failedTransactions[code] &&
			failedTransactions[code].find(
				(txn) => txn.transactionKey === txnKey
			) &&
			failedTransactions[code].find(
				(txn) => txn.transactionKey === txnKey
			).errors[Object.keys(field)[0]] &&
			failedTransactions[code]
				.find((txn) => txn.transactionKey === txnKey)
				.errors[Object.keys(field)[0]].some((err) => !err.hideError)
		);
	};

	// initializeTransaction();
	if (isClearing) {
		return null;
	}

	//moved error count outside
	const getErrorCount = () => {
		return (
			Object.keys(failedTransactions)
				.filter((ft) => !ft.includes("undefined"))
				.flatMap((code) => failedTransactions[code])
				.reduce((totalCount, txn) => {
					// Remove count if field has wallet balance error
					// If code is time_out, don't add to error count
					const errors = Object.entries(txn.errors).filter((err) =>
						err[1][0].code == "TIME_OUT" ? 0 : err[1].length
					);
					return totalCount + Object.keys(errors).length;
				}, 0) +
			Object.keys(fieldErrors).reduce(
				(accumulator, txnKey) =>
					accumulator +
					Object.keys(fieldErrors[txnKey].transactions).length,
				0
			) +
			Object.keys(paymentFieldErrors).length +
			(showAlert ? 1 : 0)
		);
	};

	const errorCount = getErrorCount();
	return (
		<>
			{selectedPartners.length == 0 && (
				<>
					<Box
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Box
							style={{
								marginBottom: "22rem",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "10px",
							}}
						>
							<img
								src={StartTransactionIcon}
								style={{ display: "block" }}
							/>
							<Typography
								style={{
									textAlign: "center",
								}}
								variant="body1"
								className="favorite-note"
							>
								Select a partner to start the transaction.
							</Typography>
						</Box>
					</Box>
				</>
			)}
			{/* Duration Loader */}
			{isValidating && (
				<FullPageLoader
					open={isValidating}
					message={validateMessageStatus}
				/>
			)}
			{errorCount > 0 ? (
				<div>
					<Alert
						variant="filled"
						severity="error"
						icon={<BlockIcon fontSize={"inherit"} />}
						onClose={closeAlert}
						style={{ width: "100%", marginLeft: "0px" }}
					>
						You have
						{" " + errorCount + " "}
						errors.
					</Alert>
				</div>
			) : null}
			{hasNoError ? (
				<div>
					<Alert
						variant="filled"
						severity="success"
						icon={<CheckCircleIcon fontSize={"inherit"} />}
						onClose={closeAlert}
						style={{
							backgroundColor: "#198754",
							font: "normal normal normal 14px Poppins-Regular",
							width: "100%",
							marginLeft: "0px",
						}}
					>
						Validation successful!
					</Alert>
				</div>
			) : null}
			{!!notActiveError && isValidated ? (
				<div>
					<Alert
						variant="filled"
						severity="error"
						icon={<BlockIcon fontSize={"inherit"} />}
						onClose={closeAlert}
						style={{ width: "100%", marginLeft: "0px" }}
					>
						<div>{notActiveError}</div>
					</Alert>
				</div>
			) : null}
			{isGenericError && isValidated ? (
				<div>
					<Alert
						variant="filled"
						severity="error"
						icon={<BlockIcon fontSize={"inherit"} />}
						onClose={closeAlert}
						style={{ width: "100%", marginLeft: "0px" }}
					>
						<div>
							Invalid Transaction
							<br />
							<br />A business rule may have not been met or a
							customer account may not be allowed to be processed.
							Kindly double check your input data fields to be
							able to meet the partner business rules and proceed
							in processing this payment.
						</div>
					</Alert>
				</div>
			) : null}
			{/* Error Timeout Spiel */}
			{timeoutError?.statusCode && (
				<div>
					<Alert
						variant="filled"
						severity="error"
						icon={<BlockIcon fontSize={"inherit"} />}
						onClose={closeAlert}
						style={{ width: "100%", marginLeft: "0px" }}
					>
						<div>
							{/* Error {timeoutError?.statusCode}:{" "} */}
							{timeoutError?.message}
						</div>
					</Alert>
				</div>
			)}
			{partnerToAdd.checkNotAllowed ? (
				<div>
					<Alert
						variant="filled"
						severity="error"
						icon={<BlockIcon fontSize={"inherit"} />}
						onClose={() => allowCheck()}
						style={{ width: "100%", marginLeft: "0px" }}
					>
						{partnerToAdd.name} does not allow cash payment.
					</Alert>
				</div>
			) : null}
			{partnerToAdd.multipleCheckNotAllowed ? (
				<div>
					<Alert
						variant="filled"
						severity="error"
						icon={<BlockIcon fontSize={"inherit"} />}
						onClose={() => allowCheck()}
						style={{ width: "100%", marginLeft: "0px" }}
					>
						Multiple partners for check payments is not allowed.
					</Alert>
				</div>
			) : null}
			{partnerToAdd.maxPartnersReached ? (
				<div>
					<Alert
						style={{ width: "100%", marginLeft: "0px" }}
						variant="filled"
						severity="error"
						icon={<BlockIcon fontSize={"inherit"} />}
						onClose={() => allowCheck()}
					>
						You have reached the max bills (30) allowed per
						transaction.
					</Alert>
				</div>
			) : null}
			{/* Error Modal */}
			{paymentErrorCode?.code &&
				paymentErrorCode?.code !== "DUP_TRANS" &&
				(paymentErrorCode?.displayType === "MODAL" ? (
					<ErrorModal
						toggleDialog={() => {
							handleClose();
							resetValidated();
						}}
						aria-labelledby="customized-dialog-title"
						open={
							paymentErrorCode?.code &&
							paymentErrorCode?.code !== "DUP_TRANS"
						}
						title={
							paymentErrorCode?.header
								? paymentErrorCode?.header
								: "Transaction Failed!"
						}
						message={paymentErrorCode?.message}
					/>
				) : (
					<Alert
						variant="filled"
						severity="error"
						icon={<BlockIcon fontSize={"inherit"} />}
						onClose={resetPaymentErrorCode}
						style={{ width: "100%", marginLeft: "0px" }}
					>
						<div>{paymentErrorCode?.message}</div>
					</Alert>
				))}
			{/* Duplicate Error Modal */}
			{paymentErrorCode?.code === "DUP_TRANS" && (
				<DuplicateErrorModal
					modalOpen={paymentErrorCode?.code === "DUP_TRANS"}
					onCloseBtn={() => {
						console.log("onCloseBtn");
						handleClose();
						resetValidated();
						setCheckDuplicate(true);
						setDuplicateTransactionList([]);
					}}
					onContinueBtn={() => {
						console.log("onContinueBtn");
						setCheckDuplicate(false);
						resetPaymentErrorCode();
					}}
				/>
			)}
			{selectedPartners.length ? (
				<Box
					display="flex"
					style={{ paddingLeft: "5px", borderBottom: "10px" }}
				>
					<Box flexGrow={1}>
						<div className={styles.txnHeader}>Transaction List</div>
					</Box>
					<Box alignSelf="flex-start">
						<Button
							startIcon={
								<ClearIcon
									style={{
										transform: "scale(1.3)",
										fill: "black",
										color: "black",
									}}
									className={classes.clearIcon}
								/>
							}
							size={"small"}
							style={{ marginTop: "-10px" }}
							onClick={clickClear}
						>
							Clear All
						</Button>
						<Button
							startIcon={
								<TrashIcon
									style={{ transform: "scale(1.2)" }}
								/>
							}
							size={"small"}
							style={{ marginTop: "-10px" }}
							onClick={handleRemoveAll}
						>
							Remove all
						</Button>
					</Box>
				</Box>
			) : !hasWalletBalance && hasWallet ? (
				<NoFunds />
			) : (
				<></>
			)}

			{/* Error Modal */}
			{paymentErrorCode?.code &&
				paymentErrorCode?.code !== "DUP_TRANS" && (
					<ErrorModal
						toggleDialog={() => {
							handleClose();
							resetValidated();
						}}
						aria-labelledby="customized-dialog-title"
						open={
							paymentErrorCode?.code &&
							paymentErrorCode?.code !== "DUP_TRANS"
						}
						title={
							productPartnerUnavailableInfo ||
							productParnetNotActive === paymentErrorCode?.message
								? "Access Denied!"
								: "Transaction Failed!"
						}
						message={paymentErrorCode?.message}
					/>
				)}

			{/* Duplicate Error Modal */}
			{paymentErrorCode?.code === "DUP_TRANS" && (
				<DuplicateErrorModal
					toggleDialog={() => {
						handleClose();
						resetValidated();
					}}
					aria-labelledby="customized-dialog-title"
					open={paymentErrorCode?.code === "DUP_TRANS"}
					title="Duplicate Transaction"
					message={paymentErrorCode?.message}
					disableBackdropClick
				/>
			)}
			<div id="transactionDetails">
				{selectedPartners.map((data, i) => {
					console.log("selected partner>>>", selectedPartners);
					return (
						!isEmpty(data) && (
							<>
								<div
									className={styles.billerHeader}
									onClick={() => handleClick(data.code)}
									key={i}
								>
									<img
										className={styles.billerImage}
										src={data.logo}
										alt={data.name}
									/>
									<label className={styles.headerLabel}>
										{data.name}
									</label>
									{partnerOpener[data.code] ? (
										<ExpandLess
											className={styles.expandIcon}
										/>
									) : (
										<ExpandMore
											className={styles.expandIcon}
										/>
									)}
								</div>
								<Collapse
									in={partnerOpener[data.code]}
									className="billerForm"
									key={data.code}
								>
									<form
										id="billsPaymentForm"
										name="billsPaymentForm"
										className={styles.transactionForm}
										noValidate
										autoComplete="off"
									>
										<ThemeProvider theme={theme}>
											{partnerTransactions[data.code] &&
												partnerTransactions[
													data.code
												].map((transaction) => {
													console.log(
														"partner transaction>>>>",
														partnerTransactions
													);
													return (
														<Box
															display={"grid"}
															gridColumn={
																"1fr minmax(1fr,160px) 170px"
															}
															key={
																transaction.transactionKey
															}
															style={{
																marginBottom:
																	"15px",
															}}
														>
															<Box
																display={"grid"}
																gridColumn={
																	"1 / 2"
																}
																gridRow={
																	"1 / -1"
																}
															>
																<Box
																	display={
																		"flex"
																	}
																	flexDirection={
																		"row"
																	}
																	flexWrap={
																		"wrap"
																	}
																	style={{
																		gap: "8px",
																	}}
																>
																	{data.parameters.verify
																		.filter(
																			(
																				field
																			) =>
																				Object.keys(
																					field
																				)[0] !==
																					"paymentMethod" &&
																				Object.keys(
																					field
																				)[0] !==
																					"otherCharges" &&
																				!Object.keys(
																					field
																				)[0].startsWith(
																					"otherInfo.CheckDetails"
																				)
																		)
																		.map(
																			(
																				field,
																				index
																			) => {
																				const fieldRules =
																					field[
																						Object.keys(
																							field
																						)[0]
																					][
																						"rules"
																					];
																				const fieldRuleDropdown =
																					Object.keys(
																						fieldRules
																					).map(
																						(
																							val
																						) =>
																							val.startsWith(
																								"in:"
																							)
																					);
																				const fieldRuleDatePicker =
																					Object.keys(
																						fieldRules
																					).map(
																						(
																							val
																						) =>
																							val.startsWith(
																								"date"
																							)
																					);
																				const dateRule =
																					Object.entries(
																						fieldRules
																					).find(
																						(
																							val
																						) =>
																							val[0].includes(
																								"date"
																							)
																					);
																				const objValue =
																					partnerTransactions[
																						data
																							.code
																					].find(
																						(
																							txn
																						) =>
																							txn.transactionKey ===
																							transaction.transactionKey
																					)[
																						Object.keys(
																							field
																						)[0]
																					];

																				return (
																					<>
																						{fieldRuleDatePicker.includes(
																							true
																						) && (
																							<DatePicker
																								dateRule={
																									dateRule
																								}
																								field={
																									field
																								}
																								classes={
																									classes
																								}
																								fieldErrors={
																									fieldErrors
																								}
																								transaction={
																									transaction
																								}
																								isFieldInvalid={
																									isFieldInvalid
																								}
																								data={
																									data
																								}
																								isValidated={
																									isValidated
																								}
																								failedTransactions={
																									failedTransactions
																								}
																								onVerifyInputChange={
																									onVerifyInputChange
																								}
																								style={{
																									width: "200px",
																								}}
																							/>
																						)}
																						{/* HANDLE AMOUNT WITH FIELD TYPE SELECT DROPDOWN */}
																						{!fieldRuleDatePicker.includes(
																							true
																						) &&
																							partnerFormAmounts.includes(
																								Object.keys(
																									field
																								)[0]
																							) &&
																							fieldRuleDropdown.includes(
																								true
																							) && (
																								<TextField
																									style={{
																										width: "200px",
																									}}
																									select={fieldRuleDropdown.includes(
																										true
																									)}
																									error={
																										(Object.keys(
																											fieldErrors
																										)
																											.length >
																											0 &&
																											fieldErrors[
																												transaction
																													.transactionKey
																											] &&
																											fieldErrors[
																												transaction
																													.transactionKey
																											]
																												.transactions[
																												Object.keys(
																													field
																												)[0]
																											] &&
																											fieldErrors[
																												transaction
																													.transactionKey
																											]
																												.transactions[
																												Object.keys(
																													field
																												)[0]
																											] !==
																												"") ||
																										(isFieldInvalid(
																											data.code,
																											transaction.transactionKey,
																											field
																										) &&
																											isValidated)
																									}
																									helperText={
																										Object.keys(
																											fieldErrors
																										)
																											.length >
																											0 &&
																										fieldErrors[
																											transaction
																												.transactionKey
																										] &&
																										fieldErrors[
																											transaction
																												.transactionKey
																										]
																											.transactions[
																											Object.keys(
																												field
																											)[0]
																										] &&
																										fieldErrors[
																											transaction
																												.transactionKey
																										]
																											.transactions[
																											Object.keys(
																												field
																											)[0]
																										] !==
																											""
																											? fieldErrors[
																													transaction
																														.transactionKey
																											  ]
																													.transactions[
																													Object.keys(
																														field
																													)[0]
																											  ]
																											: isFieldInvalid(
																													data.code,
																													transaction.transactionKey,
																													field
																											  ) &&
																											  isValidated
																											? failedTransactions[
																													data
																														.code
																											  ].find(
																													(
																														txn
																													) =>
																														txn.transactionKey ===
																														transaction.transactionKey
																											  )
																													.errors[
																													Object.keys(
																														field
																													)[0]
																											  ][0]
																													.message
																											: null
																									}
																									size="small"
																									InputProps={{
																										classes:
																											{
																												input: styles.textInput,
																												root: `${
																													objValue
																														? classes.inputWithValue
																														: ""
																												}`,
																											},
																									}}
																									id={
																										Object.keys(
																											field
																										)[0]
																									}
																									name={
																										Object.keys(
																											field
																										)[0]
																									}
																									key={
																										Object.keys(
																											field
																										)[0]
																											.label
																									}
																									label={
																										<Typography
																											className={`${
																												field[
																													Object.keys(
																														field
																													)[0]
																												]
																													.label
																													.length <
																												21
																													? classes.inputLabel
																													: classes.inputLabelSmall
																											}`}
																										>
																											{
																												field[
																													Object.keys(
																														field
																													)[0]
																												]
																													.label
																											}
																										</Typography>
																									}
																									variant="outlined"
																									onChange={(
																										event
																									) =>
																										onVerifyInputChange(
																											data.code,
																											transaction.transactionKey,
																											event
																										)
																									}
																									onKeyDown={(
																										e
																									) => {
																										barcodeScan(
																											e,
																											Object?.prototype.hasOwnProperty.call(
																												fieldRules,
																												"custom:enable_barcode"
																											),
																											updatePartnerTxn,
																											{
																												code: data.code,
																												txnKey: transaction.transactionKey,
																											}
																										);
																									}}
																								>
																									{Object.entries(
																										fieldRules
																									).map(
																										(
																											key
																										) => {
																											if (
																												key[0].startsWith(
																													"in:"
																												)
																											) {
																												const options =
																													key[1][
																														"options"
																													];
																												const dependents =
																													key[1][
																														"dependents"
																													];
																												const hasDependents =
																													dependents
																														? Object.keys(
																																dependents
																														  )
																																.length
																														: false;
																												return Object.entries(
																													options
																												).map(
																													([
																														value,
																														label,
																													]) => {
																														const item =
																															(
																																<MenuItem
																																	key={
																																		value
																																	}
																																	value={
																																		value
																																	}
																																>
																																	<Typography
																																		className={
																																			classes.inputLabel
																																		}
																																	>
																																		{
																																			label
																																		}
																																	</Typography>
																																</MenuItem>
																															);

																														if (
																															hasDependents
																														) {
																															const [
																																fieldName,
																																fieldValue,
																															] =
																																dependents[
																																	value
																																];
																															const _value =
																																transaction[
																																	fieldName
																																];

																															if (
																																fieldValue ==
																																_value
																															) {
																																return item;
																															} else {
																																return (
																																	<>

																																	</>
																																);
																															}
																														} else {
																															return item;
																														}
																													}
																												);
																											}
																										}
																									)}
																								</TextField>
																							)}
																						{!fieldRuleDatePicker.includes(
																							true
																						) &&
																							partnerFormAmounts.includes(
																								Object.keys(
																									field
																								)[0]
																							) &&
																							!fieldRuleDropdown.includes(
																								true
																							) && (
																								<NumberField
																									style={{
																										width: "200px",
																									}}
																									select={fieldRuleDropdown.includes(
																										true
																									)}
																									error={
																										(Object.keys(
																											fieldErrors
																										)
																											.length >
																											0 &&
																											fieldErrors[
																												transaction
																													.transactionKey
																											] &&
																											fieldErrors[
																												transaction
																													.transactionKey
																											]
																												.transactions[
																												Object.keys(
																													field
																												)[0]
																											] &&
																											fieldErrors[
																												transaction
																													.transactionKey
																											]
																												.transactions[
																												Object.keys(
																													field
																												)[0]
																											] !==
																												"") ||
																										(isFieldInvalid(
																											data.code,
																											transaction.transactionKey,
																											field
																										) &&
																											isValidated)
																									}
																									helperText={
																										Object.keys(
																											fieldErrors
																										)
																											.length >
																											0 &&
																										fieldErrors[
																											transaction
																												.transactionKey
																										] &&
																										fieldErrors[
																											transaction
																												.transactionKey
																										]
																											.transactions[
																											Object.keys(
																												field
																											)[0]
																										] &&
																										fieldErrors[
																											transaction
																												.transactionKey
																										]
																											.transactions[
																											Object.keys(
																												field
																											)[0]
																										] !==
																											""
																											? fieldErrors[
																													transaction
																														.transactionKey
																											  ]
																													.transactions[
																													Object.keys(
																														field
																													)[0]
																											  ]
																											: isFieldInvalid(
																													data.code,
																													transaction.transactionKey,
																													field
																											  ) &&
																											  isValidated
																											? failedTransactions[
																													data
																														.code
																											  ].find(
																													(
																														txn
																													) =>
																														txn.transactionKey ===
																														transaction.transactionKey
																											  )
																													.errors[
																													Object.keys(
																														field
																													)[0]
																											  ][0]
																													.message
																											: null
																									}
																									size="small"
																									InputProps={{
																										classes:
																											{
																												input: styles.textInput,
																												root: `${
																													objValue
																														? classes.inputWithValue
																														: ""
																												}`,
																											},
																									}}
																									id={
																										Object.keys(
																											field
																										)[0]
																									}
																									name={
																										Object.keys(
																											field
																										)[0]
																									}
																									key={
																										Object.keys(
																											field
																										)[0]
																											.label
																									}
																									label={
																										<Typography
																											className={`${
																												field[
																													Object.keys(
																														field
																													)[0]
																												]
																													.label
																													.length <
																												21
																													? classes.inputLabel
																													: classes.inputLabelSmall
																											}`}
																										>
																											{
																												field[
																													Object.keys(
																														field
																													)[0]
																												]
																													.label
																											}
																										</Typography>
																									}
																									variant="outlined"
																									fieldOnChange={(
																										event
																									) =>
																										onVerifyInputChange(
																											data.code,
																											transaction.transactionKey,
																											event
																										)
																									}
																								/>
																							)}
																						{!fieldRuleDatePicker.includes(
																							true
																						) &&
																							!partnerFormAmounts.includes(
																								Object.keys(
																									field
																								)[0]
																							) && (
																								<>
																									{data.code ===
																										"SSS01" &&
																									Object.keys(
																										field
																									)[0] ===
																										"otherInfo.PaymentType" &&
																									transaction[
																										"otherInfo.PayFor"
																									] ===
																										"PRN" ? (
																										""
																									) : (
																										<TextField
																											style={{
																												width: "200px",
																											}}
																											select={fieldRuleDropdown.includes(
																												true
																											)}
																											error={
																												(Object.keys(
																													fieldErrors
																												)
																													.length >
																													0 &&
																													fieldErrors[
																														transaction
																															.transactionKey
																													] &&
																													fieldErrors[
																														transaction
																															.transactionKey
																													]
																														.transactions[
																														Object.keys(
																															field
																														)[0]
																													] &&
																													fieldErrors[
																														transaction
																															.transactionKey
																													]
																														.transactions[
																														Object.keys(
																															field
																														)[0]
																													] !==
																														"") ||
																												(isFieldInvalid(
																													data.code,
																													transaction.transactionKey,
																													field
																												) &&
																													isValidated)
																											}
																											helperText={
																												Object.keys(
																													fieldErrors
																												)
																													.length >
																													0 &&
																												fieldErrors[
																													transaction
																														.transactionKey
																												] &&
																												fieldErrors[
																													transaction
																														.transactionKey
																												]
																													.transactions[
																													Object.keys(
																														field
																													)[0]
																												] &&
																												fieldErrors[
																													transaction
																														.transactionKey
																												]
																													.transactions[
																													Object.keys(
																														field
																													)[0]
																												] !==
																													""
																													? fieldErrors[
																															transaction
																																.transactionKey
																													  ]
																															.transactions[
																															Object.keys(
																																field
																															)[0]
																													  ]
																													: isFieldInvalid(
																															data.code,
																															transaction.transactionKey,
																															field
																													  ) &&
																													  isValidated
																													? failedTransactions[
																															data
																																.code
																													  ].find(
																															(
																																txn
																															) =>
																																txn.transactionKey ===
																																transaction.transactionKey
																													  )
																															.errors[
																															Object.keys(
																																field
																															)[0]
																													  ][0]
																															.message
																													: null
																											}
																											size="small"
																											InputProps={{
																												startAdornment:
																													field[
																														Object.keys(
																															field
																														)[0]
																													]
																														.label ===
																													"Mobile Number" ? (
																														<InputAdornment position="start">
																															+63{" "}
																														</InputAdornment>
																													) : null,
																												classes:
																													{
																														input: styles.textInput,
																														root: `${
																															objValue
																																? classes.inputWithValue
																																: ""
																														}`,
																													},
																											}}
																											id={
																												Object.keys(
																													field
																												)[0]
																											}
																											name={
																												Object.keys(
																													field
																												)[0]
																											}
																											key={
																												Object.keys(
																													field
																												)[0]
																													.label
																											}
																											label={
																												<Typography
																													className={`${
																														field[
																															Object.keys(
																																field
																															)[0]
																														]
																															.label
																															.length <
																														21
																															? classes.inputLabel
																															: classes.inputLabelSmall
																													}`}
																												>
																													{
																														field[
																															Object.keys(
																																field
																															)[0]
																														]
																															.label
																													}
																												</Typography>
																											}
																											variant="outlined"
																											onChange={(
																												event
																											) =>
																												onVerifyInputChange(
																													data.code,
																													transaction.transactionKey,
																													event
																												)
																											}
																											onKeyDown={(
																												e
																											) => {
																												barcodeScan(
																													e,
																													Object?.prototype.hasOwnProperty.call(
																														fieldRules,
																														"custom:enable_barcode"
																													),
																													updatePartnerTxn,
																													{
																														code: data.code,
																														txnKey: transaction.transactionKey,
																													}
																												);
																											}}
																										>
																											{fieldRuleDropdown.includes(
																												true
																											) &&
																												Object.entries(
																													fieldRules
																												).map(
																													(
																														key
																													) => {
																														if (
																															key[0].startsWith(
																																"in:"
																															)
																														) {
																															const options =
																																key[1][
																																	"options"
																																];
																															const dependents =
																																key[1][
																																	"dependents"
																																];
																															const hasDependents =
																																dependents
																																	? Object.keys(
																																			dependents
																																	  )
																																			.length
																																	: false;
																															return Object.entries(
																																options
																															).map(
																																([
																																	value,
																																	label,
																																]) => {
																																	const item =
																																		(
																																			<MenuItem
																																				key={
																																					value
																																				}
																																				value={
																																					value
																																				}
																																			>
																																				<Typography
																																					className={
																																						classes.inputLabel
																																					}
																																				>
																																					{
																																						label
																																					}
																																				</Typography>
																																			</MenuItem>
																																		);

																																	if (
																																		hasDependents
																																	) {
																																		const [
																																			fieldName,
																																			fieldValue,
																																		] =
																																			dependents[
																																				value
																																			];
																																		const _value =
																																			transaction[
																																				fieldName
																																			];

																																		if (
																																			fieldValue ==
																																			_value
																																		) {
																																			return item;
																																		} else {
																																			return (
																																				<>

																																				</>
																																			);
																																		}
																																	} else {
																																		return item;
																																	}
																																}
																															);
																														}
																													}
																												)}
																										</TextField>
																									)}
																								</>
																							)}
																					</>
																				);
																			}
																		)}
																	{/* START SSS01/SSSC1 DETAILS HANDLING */}
																	{SSS_CONTRIBUTION.includes(
																		data.code
																	) &&
																		!isEmpty(
																			validTransactions
																		) &&
																		validTransactions[
																			data
																				.code
																		] &&
																		!isEmpty(
																			validTransactions[
																				data
																					.code
																			].find(
																				(
																					data
																				) =>
																					data.account ===
																					transaction.referenceNumber
																			)
																		) && (
																			<SSSContributionDetails
																				styles={
																					styles
																				}
																				classes={
																					classes
																				}
																				validTransactions={
																					validTransactions
																				}
																				data={
																					data
																				}
																				transaction={
																					transaction
																				}
																			/>
																		)}
																	{/* END SSS01 DETAILS HANDLING */}
																	{/* START SSS02/SSSC2 DETAILS HANDLING */}
																	{SSS_SHORT_TERM_LOANS.includes(
																		data.code
																	) &&
																		!isEmpty(
																			validTransactions
																		) &&
																		validTransactions[
																			data
																				.code
																		] &&
																		!isEmpty(
																			validTransactions[
																				data
																					.code
																			].find(
																				(
																					data
																				) =>
																					data.account ===
																					transaction.referenceNumber
																			)
																		) && (
																			<SSSShortTermLoanDetails
																				styles={
																					styles
																				}
																				classes={
																					classes
																				}
																				validTransactions={
																					validTransactions
																				}
																				data={
																					data
																				}
																				transaction={
																					transaction
																				}
																			/>
																		)}
																	{/* END SSS01 DETAILS HANDLING */}
																</Box>
															</Box>

															<Box
																display={"grid"}
																gridColumn={
																	"3 / 4"
																}
																gridRow={
																	"1 / -1"
																}
															>
																<Box
																	display={
																		"flex"
																	}
																	justifyContent={
																		"end"
																	}
																	alignItems={
																		"start"
																	}
																>
																	<Button
																		style={{
																			paddingInline:
																				"30px",
																			paddingBlock:
																				"8px",
																		}}
																		startIcon={
																			// <TrashIcon
																			// 	style={{
																			// 		transform:
																			// 			"scale(1.2)",
																			// 	}}
																			// />
																			<RemoveIcon />
																		}
																		onClick={() =>
																			clickRemove(
																				data.code,
																				transaction.transactionKey
																			)
																		}
																	>
																		<span
																			className={
																				styles.removeButton
																			}
																			size="small"
																		>
																			Remove
																		</span>
																	</Button>
																</Box>
															</Box>
														</Box>
													);
												})}

											{/* <div
												className={cx(
													styles.addTransactionContainer
												)}
											>
												<Button
													onClick={() =>
														addTransaction(
															data.code
														)
													}
												>
													<AddToPhotosIcon
														className={
															styles.addTransactionIcon
														}
													/>
													<span
														className={
															styles.addTransactionButton
														}
													>
														Add Transaction
													</span>
												</Button>
											</div> */}
										</ThemeProvider>
									</form>
								</Collapse>
							</>
						)
					);
				})}
			</div>
			{selectedPartners.length > 0 && (
				<>
					<BuyLoadPaymentForm
						classes={classes}
						onPaymentModeChange={onPaymentModeChange}
						selectedPaymentMethod={selectedPaymentMethod}
						selectedPartners={selectedPartners}
						theme={theme}
						onPaymentDetailsChange={onPaymentDetailsChange}
						paymentDetails={paymentDetails}
						paymentFieldErrors={paymentFieldErrors}
						failedTransactions={failedTransactions}
						isValidated={isValidated}
						selectedDate={selectedDate}
						customerDetailsCheck={isCustomerDetailsCheck}
						handleCustomerDetailsCheck={handleCustomerDetailsCheck}
						handleDateChange={handleDateChange}
						privacyInfoCheck={privacyInfoCheck}
					/>
					<Divider variant="middle" className={styles.divider} />
					<Button
						variant="contained"
						color="inherit"
						size="medium"
						className={classes.validateBtn}
						disableElevation
						onClick={() => {
							if (
								privacyInfoCheck.transparency &&
								privacyInfoCheck.dataIntegrity
							) {
								handleVerify();
								toggleShowValidateExpiryModal(false);
								// enableBuyLoadCommit();
								setPrivacyInfoCheck({
									...privacyInfoCheck,
									transparencyErrMsg: "",
									dataIntegrityErrMsg: "",
								});
							} else {
								setDialogModal(true);
								setPrivacyInfoCheck({
									...privacyInfoCheck,
									transparencyErrMsg:
										!privacyInfoCheck.transparency
											? "This checkbox is required."
											: "",
									dataIntegrityErrMsg:
										!privacyInfoCheck.dataIntegrity
											? "This checkbox is required."
											: "",
								});
							}
						}}
						disabled={isValidating}
					>
						Validate Transaction Details
					</Button>
					{txnToRemove.code ? (
						<Dialog
							onClose={handleClose}
							aria-labelledby="customized-dialog-title"
							open={showRemoveModal}
							disableBackdropClick
						>
							<DialogTitle
								id="customized-dialog-title"
								onClose={handleClose}
							>
								Remove Transactions
							</DialogTitle>
							<DialogContent dividers>
								<Typography
									gutterBottom
									className={styles.dialogContent}
								>
									Are you sure you want to remove{" "}
									<b>
										Account No.{" "}
										{
											partnerTransactions[
												txnToRemove.code
											].find(
												(txn) =>
													txn.transactionKey ===
													txnToRemove.txnKey
											).referenceNumber
										}
									</b>{" "}
									under{" "}
									<b>
										{
											selectedPartners.find(
												(partner) =>
													partner.code ===
													txnToRemove.code
											).name
										}
									</b>{" "}
									from this transaction?
								</Typography>
							</DialogContent>
							<DialogActions>
								<WhiteColorButton
									autoFocus
									onClick={handleClose}
									color="primary"
								>
									Cancel
								</WhiteColorButton>
								<GreenColorButton
									autoFocus
									onClick={() => {
										removeTransaction(
											txnToRemove.code,
											txnToRemove.txnKey
										);
									}}
									color="primary"
								>
									Confirm
								</GreenColorButton>
							</DialogActions>
						</Dialog>
					) : null}
					<Dialog
						onClose={handleClose}
						aria-labelledby="customized-dialog-title"
						open={showClearModal}
						disableBackdropClick
					>
						<DialogTitle
							id="customized-dialog-title"
							onClose={handleClose}
						>
							Clear Transactions
						</DialogTitle>
						<DialogContent dividers>
							<Typography
								gutterBottom
								className={styles.dialogContent}
							>
								Are you sure you want to clear all transactions?
							</Typography>
						</DialogContent>
						<DialogActions>
							<WhiteColorButton
								autoFocus
								onClick={handleClose}
								color="primary"
							>
								Cancel
							</WhiteColorButton>
							<GreenColorButton
								autoFocus
								onClick={handleClear}
								color="primary"
							>
								Confirm
							</GreenColorButton>
						</DialogActions>
					</Dialog>
					<DialogModal
						imgSrc={failedIcon}
						open={showDialogModal}
						toggleDialog={() => setDialogModal(false)}
						title="Data Privacy Information and Consent"
						message={
							"The consent for Transparency and/or Data Integrity was not provided. Please ensure the checkbox was ticked before proceeding with the transaction. "
						}
						buttonLabel="Okay"
					/>
					<DialogModal
						imgSrc={failedIcon}
						open={showServiceFeeFailedDialogModal}
						toggleDialog={() =>
							setShowServiceFeeFailedDialogModal(false)
						}
						title="Service Fee Error"
						message={
							"Unable to retrieve the service fee for this partner. Please try again later."
						}
						buttonLabel="Okay"
					/>
					<RetryDialog
						open={isServiceFeeRetryDialogOpen}
						toggleDialog={() => {
							toggleServiceFeeRetryDialog();
						}}
					/>
					<ExpiredValidateModal
						open={openExpireValidateModal}
						handleOnCloseModal={handleOnCloseExpireValidationModal}
					/>
					<Dialog
						onClose={handleClose}
						aria-labelledby="customized-dialog-title"
						open={showResetModal}
						disableBackdropClick
					>
						<DialogTitle
							id="customized-dialog-title"
							onClose={handleClose}
						>
							Remove Transactions
						</DialogTitle>
						<DialogContent dividers>
							<Typography
								gutterBottom
								className={styles.dialogContent}
							>
								Are you sure you want to remove all
								transactions?
							</Typography>
						</DialogContent>
						<DialogActions>
							<WhiteColorButton
								autoFocus
								onClick={handleClose}
								color="primary"
							>
								Cancel
							</WhiteColorButton>
							<GreenColorButton
								autoFocus
								onClick={resetTransaction}
								color="primary"
							>
								Confirm
							</GreenColorButton>
						</DialogActions>
					</Dialog>
				</>
			)}
		</>
	);
}

export default connect(
	(state) => ({
		hasWallet: state.walletBalance.id,
		banks: state.banks.banks,
		showAlert: state.walletBalance.showAlert,
		hasWalletBalance: state.walletBalance.balance > 0,
		selectedPartners: state.partnerList.selectedPartners,
		successValidation: state.validation.successValidation,
		isValidating: state.validation.isValidating,
		isValidated: state.validation.isValidated,
		isServiceFeeRetryDialogOpen:
			state.partnerList.isServiceFeeRetryDialogOpen,
		partnerToAdd: state.partnerList.partnerToAdd,
		partnerTransactions: state.partnerList.partnerTransactions,
		selectedPaymentMethod: state.partnerList.selectedPaymentMethod,
		failedTransactions: state.validation.failedTransactions,
		validTransactions: state.validation.validTransactions,
		paymentDetails: state.validation.paymentDetails,
		partnerOpener: state.partnerList.partnerOpener,
		isGenericError: state.validation.isGenericError,
		notActiveError: state.validation.notActiveError,
		timeoutError: state.validation.timeoutError,
		validateMessageStatus: state.validation.validateMessageStatus,
		paymentErrorCode: state.transaction.paymentErrorCode,
		hasNoError:
			state.validation.isValidated &&
			isEmpty(state.validation.failedTransactions) &&
			!state.validation.isGenericError &&
			!state.validation.notActiveError &&
			!state.validation.timeoutError.code &&
			state.walletBalance.isWalletSufficient,
	}),
	{
		verifyActionCreator,
		toggleServiceFeeRetryDialog,
		clearValidation,
		resetCustomPartnerServiceFee,
		updatePartnerServiceFee,
		resetValidated,
		allowCheck,
		removePartnerTxn,
		resetPartnerTxn,
		addPartnerTxn,
		updatePartnerTxn,
		selectPaymentMethod,
		setPaymentDetails,
		clearPartnerTxns,
		setMultipleCheckNotAllowed,
		togglePartnerOpener,
		enableCommit,
		enableBuyLoadCommit,
		toggleShowValidateExpiryModal,
		getBanksAction,
		setBankList,
		getPartnerDetailsAction,
		setIsWalletSufficient,
		setPaymentErrorCode,
		setCheckDuplicate,
		setDuplicateTransactionList,
	}
)(TransactionList);
