export const partnersDataDEV = [
	{
		name: "DITO",
		code: "AECOR",
		description: "Angeles Electric Corporation",
		category: "Prepaid Mobile Load",
		type: "BATCH",
		logo: "https://stg-bc-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/250/AECOR.png",
		isMultipleBills: 0,
		isCde: 0,
		isAsync: 1,
		status: "ACTIVE",
		isFavorite: 1,
		orderFavorite: 1,
		is_api3_enabled: true,
		is_enabled: false,
		isSelected: false,
	},
	{
		name: "Globe",
		code: "ANTEC",
		description: "ANTIQUE ELECTRIC COOPERATIVE, INC.",
		category: "Prepaid Mobile Load",
		type: "BATCH",
		logo: "https://cbci-mecom-dev-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/drafts/DEFAULT.png",
		isMultipleBills: 0,
		isCde: 0,
		isAsync: 1,
		status: "ACTIVE",
		isFavorite: 1,
		orderFavorite: 2,
		is_api3_enabled: true,
		is_enabled: true,
		isSelected: false,
	},
	{
		name: "Smart",
		code: "RTI01",
		description: "Radius Telecoms Inc",
		category: "Internet/Cable TV",
		type: "RTP",
		logo: "https://stg-bc-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/250/RTI01.png",
		isMultipleBills: 0,
		isCde: 0,
		isAsync: 0,
		status: "ACTIVE",
		isFavorite: 1,
		orderFavorite: 3,
		is_api3_enabled: true,
		is_enabled: true,
		isSelected: true,
	},
	{
		name: "Test Wallet",
		code: "APEC1",
		description: "Albay Power and Energy Corp.",
		category: "Retailer Wallet Top Up",
		type: "BATCH",
		logo: "https://cbci-mecom-dev-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/drafts/DEFAULT.png",
		isMultipleBills: 0,
		isCde: 0,
		isAsync: 1,
		status: "ACTIVE",
		isFavorite: 0,
		orderFavorite: 0,
		is_api3_enabled: true,
		is_enabled: false,
		isSelected: false,
	},
];

export const formDataDEV = {
	data: {
		code: "RTI01",
		isCde: 0,
		isAsync: 0,
		name: "SMART",
		description: "Radius Telecoms Inc",
		logo: "https://stg-bc-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/250/RTI01.png",
		category: "Internet/Cable TV",
		type: "RTP",
		isMultipleBills: 0,
		status: "ACTIVE",
		parameters: {
			verify: [
				{
					mobileNumber: {
						label: "Mobile Number",
						description: "Mobile Number",
						rules: {
							required: {
								code: 4,
								message: "Please provide the mobile number.",
							},
							numeric: {
								code: 8,
								message:
									"Please enter the mobile number in numeric format.",
							},
							"digits:10": {
								code: 5,
								message: "The mobile number must be 10 digits.",
							},
						},
					},
				},
				{
					paymentMethod: {
						label: "Payment Method",
						description: "Payment Method",
						rules: {
							required: {
								code: 4,
								message: "Please provide the payment method.",
							},
							"in:CASH,CHECK": {
								code: 10,
								message:
									"The payment method selected is invalid. Please try selecting this again.",
								options: {
									CASH: "CASH",
									CHECK: "CHECK",
								},
							},
						},
					},
				},
				{
					"otherInfo.LoadCategory": {
						label: "Load Category",
						description: "Load Category",
						rules: {
							required: {
								code: 4,
								message: "Please provide the category.",
							},
							"in:category": {
								code: 10,
								message:
									"The category type selected is invalid. Please try selecting this again.",
								options: {
									All: "All",
									"Best Deals": "Best Deals",
									Data: "Data",
									Regular: "Regular",
									"Top Promos": "Top Promos",
								},
							},
						},
					},
				},
				{
					"otherInfo.Promos": {
						label: "Promos",
						description: "Promos",
						rules: {
							required: {
								code: 4,
								message: "Please provide the promos.",
							},
							"in:category": {
								code: 10,
								message:
									"The category type selected is invalid. Please try selecting this again.",
								options: {
									99: "Power Ticktok 99",
									149: "Power Tick Tok 149",
									449: "Power Tick Tok 449",
								},
							},
						},
					},
				},
				{
					otherCharges: {
						label: "Other Charges",
						description: "Service Fee",
						rules: {
							required: {
								code: 4,
								message: "Please provide the other charges.",
							},
							numeric: {
								code: 8,
								message:
									"Please enter the other charges in numeric format.",
							},
							"min:0": {
								code: 6,
								message:
									"The other charges must be at least Php0.00.",
							},
							service_fee: {
								code: 16,
								message:
									"The other charges provided is invalid. Please try again.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.CheckNo": {
						label: "Check Number",
						description: "Check Number",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the check number",
							},
							numeric: {
								code: 8,
								message:
									"Please enter the check number in numeric format.",
							},
							"max:255": {
								code: 5,
								message:
									"The check number must be at most 255 digits.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.CheckDate": {
						label: "Check Date",
						description: "Check Date",
						rules: {
							"date:MM/DD/YYYY": {
								code: 15,
								message:
									"Please make sure the check date is in this format: MM/DD/YYYY.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.Amount": {
						label: "Amount",
						description: "Amount",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the amount.",
							},
							numeric: {
								code: 8,
								message:
									"Please enter the amount in numeric format.",
							},
							"min:0": {
								code: 6,
								message:
									"The minimum amount for payments must be at least Php0.00. Please enter a higher amount.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.BankBranch": {
						label: "Bank Branch",
						description: "Bank Branch",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the bank branch.",
							},
							bank_input: {
								code: 46,
								message:
									"The bank branch only accepts alphanumeric, spaces and special characters ie. &, ', -, ( ), and ,.",
							},
							"max:255": {
								code: 5,
								message:
									"The bank branch must be at most 255 digits/characters.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.BankName": {
						label: "Bank Name",
						description: "Bank Name",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the bank name.",
							},
							bank_input: {
								code: 46,
								message:
									"The bank name only accepts alphanumeric, spaces and special characters ie. &, ', -, ( ), and ,.",
							},
							"max:255": {
								code: 5,
								message:
									"The bank name must be at most 255 digits/characters.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.CheckType": {
						label: "Check Type",
						description: "Check Type",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the Check Type.",
							},
							"in:Local,Regional,Manager's Check": {
								code: 10,
								message:
									"The check type selected is invalid. Please try selecting this again.",
								options: {
									Local: "Local",
									Regional: "Regional",
									"Manager's Check": "Manager's Check",
								},
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.BankAccountNumber": {
						label: "Bank Account No.",
						description: "Bank Account No.",
						rules: {
							alpha_num: {
								code: 18,
								message:
									"The bank account no. should be in alpha-numeric format.",
							},
							"max:255": {
								code: 5,
								message:
									"The bank account no. must be at most 255 digits/characters.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.BankCode": {
						label: "Bank Code",
						description: "Bank Code",
						rules: {
							"max:255": {
								code: 5,
								message:
									"The bank code must be at most 255 digits/characters.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.ContactNumber": {
						label: "Contact Number",
						description: "Contact Number",
						rules: {
							numeric: {
								code: 8,
								message:
									"Please enter the contact number in numeric format.",
							},
							"max:15": {
								code: 5,
								message:
									"The contact number must be at most 15 digits/characters.",
							},
						},
					},
				},
			],
			transact: [
				{
					clientReference: {
						label: "Client Reference Number",
						description:
							"Client unique transaction reference number",
						rules: {
							alpha_dash: {
								code: 9,
								message:
									"Please make sure that the client reference number is in alpha dash format.",
							},
							required: {
								code: 4,
								message:
									"Please provide the client reference number.",
							},
							unique_crn: {
								code: 11,
								message:
									"This client reference number already exists.",
							},
						},
					},
				},
				{
					validationNumber: {
						label: "Validation Number",
						description: "Reference for checking validity",
						rules: {
							alpha_dash: {
								code: 9,
								message:
									"Please make sure that the validation number is in alpha dash format.",
							},
							required: {
								code: 4,
								message:
									"Please provide the validation number.",
							},
							verification: {
								code: 14,
								message:
									"The validation number provided is invalid. Please try entering this again.",
							},
						},
					},
				},
			],
		},
	},
};

export const partnersDataSTG = [
	{
		name: "DITO",
		code: "PLCTG",
		description: "LANDCO",
		category: "Prepaid Mobile Load",
		type: "BATCH",
		logo: "https://stg-bc-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/250/PLCTG.png",
		isMultipleBills: 0,
		isCde: 0,
		isAsync: 0,
		status: "ACTIVE",
		isFavorite: 1,
		orderFavorite: 1,
		is_api3_enabled: true,
		is_enabled: true,
		isSelected: false,
	},
	{
		name: "Globe",
		code: "AECOR",
		description: "Angeles Electric Corporation",
		category: "Prepaid Mobile Load",
		type: "BATCH",
		logo: "https://stg-bc-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/250/AECOR.png",
		isMultipleBills: 0,
		isCde: 0,
		isAsync: 1,
		status: "ACTIVE",
		isFavorite: 1,
		orderFavorite: 2,
		is_api3_enabled: true,
		is_enabled: false,
		isSelected: false,
	},
	{
		name: "Smart",
		code: "PLYHA",
		description: "LANDCO",
		category: "Prepaid Mobile Load",
		type: "BATCH",
		logo: "https://stg-bc-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/250/PLYHA.png?1697887844.057454",
		isMultipleBills: 0,
		isCde: 0,
		isAsync: 0,
		status: "ACTIVE",
		isFavorite: 1,
		orderFavorite: 3,
		is_api3_enabled: true,
		is_enabled: true,
		isSelected: false,
	},
	{
		name: "Test Wallet",
		code: "ANTEC",
		description: "ANTIQUE ELECTRIC COOPERATIVE, INC.",
		category: "Retailer Wallet Top Up",
		type: "BATCH",
		logo: "https://stg-bc-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/250/ANTEC.png",
		isMultipleBills: 0,
		isCde: 0,
		isAsync: 1,
		status: "ACTIVE",
		isFavorite: 0,
		orderFavorite: 0,
		is_api3_enabled: true,
		is_enabled: false,
		isSelected: false,
	},
];

export const formDataSTG = {
	data: {
		code: "PLYHA",
		isCde: 0,
		isAsync: 0,
		name: "SMART",
		description: "LANDCO",
		logo: "https://stg-bc-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/250/PLYHA.png?1697887844.057454",
		category: "Real Estate",
		type: "BATCH",
		isMultipleBills: 0,
		status: "ACTIVE",
		parameters: {
			verify: [
				{
					mobileNumber: {
						label: "Mobile Number",
						description: "Mobile Number",
						rules: {
							required: {
								code: 4,
								message: "Please provide the mobile number.",
							},
							numeric: {
								code: 8,
								message:
									"Please make sure that the mobile number is in alpha dash format.",
							},
							"size:10": {
								code: 5,
								message: "The mobile number must be 10 digit.",
							},
						},
					},
				},
				{
					paymentMethod: {
						label: "Payment Method",
						description: "Payment Method",
						rules: {
							required: {
								code: 4,
								message: "Please provide the payment method.",
							},
							"in:CASH,CHECK": {
								code: 10,
								message:
									"The payment method selected is invalid. Please try selecting this again.",
								options: {
									CASH: "CASH",
									CHECK: "CHECK",
								},
							},
						},
					},
				},
				{
					otherCharges: {
						label: "Other Charges",
						description: "Service Fee",
						rules: {
							required: {
								code: 4,
								message: "Please provide the other charges.",
							},
							numeric: {
								code: 8,
								message:
									"Please enter the other charges in numeric format.",
							},
							"min:0": {
								code: 6,
								message:
									"The other charges must be at least Php0.00.",
							},
							fee: {
								code: 16,
								message:
									"The other charges provided is invalid. Please try again.",
							},
						},
					},
				},
				{
					"otherInfo.LoadCategory": {
						label: "Load Category",
						description: "Load Category",
						rules: {
							required: {
								code: 4,
								message: "Please provide the category.",
							},
							"in:category": {
								code: 10,
								message:
									"The category type selected is invalid. Please try selecting this again.",
								options: {
									All: "All",
									"Best Deals": "Best Deals",
									Data: "Data",
									Regular: "Regular",
									"Top Promos": "Top Promos",
								},
							},
						},
					},
				},
				{
					"otherInfo.Promos": {
						label: "Promos",
						description: "Promos",
						rules: {
							required: {
								code: 4,
								message: "Please provide the promos.",
							},
							"in:category": {
								code: 10,
								message:
									"The category type selected is invalid. Please try selecting this again.",
								options: {
									99: "Power Ticktok 99",
									149: "Power Tick Tok 149",
									449: "Power Tick Tok 449",
								},
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.CheckNo": {
						label: "Check Number",
						description: "Check Number",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the check number",
							},
							check_validity: {
								code: 50,
								message:
									"Check No. already been used in another transaction. Kindly use another check to process this transaction.",
							},
							numeric: {
								code: 8,
								message:
									"Please enter the check number in numeric format.",
							},
							"max:255": {
								code: 5,
								message:
									"The check number must be at most 255 digits.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.CheckDate": {
						label: "Check Date",
						description: "Check Date",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the check date.",
							},
							"date:MM/DD/YYYY": {
								code: 15,
								message:
									"Please make sure the check date is in this format: MM/DD/YYYY.",
							},
							bank_date: {
								code: 51,
								message:
									"The date must be either the present date or a backdate of up to 175 days from the check date.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.Amount": {
						label: "Amount",
						description: "Amount",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the check amount.",
							},
							numeric: {
								code: 8,
								message:
									"Please enter the amount in numeric format.",
							},
							check_amount: {
								code: 56,
								message:
									"The check amount must be greater than or equal to the amount.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.BankBranch": {
						label: "Bank Branch",
						description: "Bank Branch",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the bank branch.",
							},
							bank_input: {
								code: 46,
								message:
									"The bank branch only accepts alphanumeric, spaces and special characters ie. &, ', -, ( ), and ,.",
							},
							"max:255": {
								code: 5,
								message:
									"The bank branch must be at most 255 digits/characters.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.CheckType": {
						label: "Check Type",
						description: "Check Type",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the Check Type.",
							},
							"in:Local,Regional,Manager's Check": {
								code: 10,
								message:
									"The check type selected is invalid. Please try selecting this again.",
								options: {
									Local: "Local",
									Regional: "Regional",
									"Manager's Check": "Manager's Check",
								},
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.BankAccountNumber": {
						label: "Bank Account No.",
						description: "Bank Account No.",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message:
									"Please provide the bank account number.",
							},
							alpha_num: {
								code: 18,
								message:
									"The bank account no. should be in alpha-numeric format.",
							},
							"max:255": {
								code: 5,
								message:
									"The bank account no. must be at most 255 digits/characters.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.BankCode": {
						label: "Bank Code",
						description: "Bank Code",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the bank.",
							},
							alpha_num: {
								code: 18,
								message:
									"The bank should be in alpha-numeric format.",
							},
							"max:255": {
								code: 5,
								message:
									"The bank must be at most 255 digits/characters.",
							},
							bank: {
								code: 10,
								message:
									"The bank selected is invalid, Please try selecting this again.",
							},
						},
					},
				},
				{
					"otherInfo.CheckDetails.ContactNumber": {
						label: "Contact Number",
						description: "Contact Number",
						rules: {
							"required_if:paymentMethod,CHECK": {
								code: 4,
								message: "Please provide the contact number.",
							},
							numeric: {
								code: 8,
								message:
									"Please enter the contact number in numeric format.",
							},
							"max:15": {
								code: 5,
								message:
									"The contact number must be at most 15 digits/characters.",
							},
						},
					},
				},
			],
			transact: [
				{
					clientReference: {
						label: "Client Reference Number",
						description:
							"Client unique transaction reference number",
						rules: {
							required: {
								code: 4,
								message:
									"Please provide the client reference number.",
							},
							alpha_dash: {
								code: 9,
								message:
									"Please make sure that the client reference number is in alpha dash format.",
							},
							unique_crn: {
								code: 11,
								message:
									"This client reference number already exists.",
							},
						},
					},
				},
				{
					validationNumber: {
						label: "Validation Number",
						description: "Reference for checking validity",
						rules: {
							required: {
								code: 4,
								message:
									"Please provide the validation number.",
							},
							alpha_dash: {
								code: 9,
								message:
									"Please make sure that the validation number is in alpha dash format.",
							},
							verification: {
								code: 14,
								message:
									"The validation number provided is invalid. Please try entering this again.",
							},
						},
					},
				},
			],
		},
	},
};
