import { TextField, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";

const PhilhealthNewDetails = (props) => {
    const { validTransactions, data, transaction, styles, classes } = props;
    const details = validTransactions[data.code].find(data => data.account === transaction.referenceNumber)
    return (
        <>
            {!isEmpty(details) && !isEmpty(details['details']) &&(
                <>
                    <TextField size="small" fullWidth variant="outlined" name={'payorName'}
                        style={{width: "200px"}}
                        InputProps={{
                            classes: {
                                input: styles.textInput,
                                root: classes.inputWithValue,
                            },
                            disabled: true
                        }}
                        label={
                            <Typography className={classes.inputLabel}>
                                Payor Name
                            </Typography>
                        }
                        value={details['details']['payorName']}
                    ></TextField>
                    <TextField size="small" fullWidth variant="outlined" name={'category'}
                        style={{width: "200px"}}
                        InputProps={{
                            classes: {
                                input: styles.textInput,
                                root: classes.inputWithValue,
                            },
                            disabled: true
                        }}
                        label={
                            <Typography className={classes.inputLabel}>
                                Payor Category
                            </Typography>
                        }
                        value={details['details']['category']}
                    ></TextField>
                    <TextField size="small" fullWidth variant="outlined" name={'periodStart'}
                        style={{width: "200px"}}
                        InputProps={{
                            classes: {
                                input: styles.textInput,
                                root: classes.inputWithValue,
                            },
                            disabled: true
                        }}
                        label={
                            <Typography className={classes.inputLabel}>
                                Applicable Period Start
                            </Typography>
                        }
                        value={details['details']['periodStart']}
                    ></TextField>
                    <TextField size="small" fullWidth variant="outlined" name={'periodEnd'}
                        style={{width: "200px"}}
                        InputProps={{
                            classes: {
                                input: styles.textInput,
                                root: classes.inputWithValue,
                            },
                            disabled: true
                        }}
                        label={
                            <Typography className={classes.inputLabel}>
                                Applicable Period End
                            </Typography>
                        }
                        value={details['details']['periodEnd']}
                    ></TextField>
                    {details['details']['dueDate'] ? 
                        <TextField size="small" fullWidth variant="outlined" name={'dueDate'}
                            style={{width: "200px"}}
                            InputProps={{
                                classes: {
                                    input: styles.textInput,
                                    root: classes.inputWithValue,
                                },
                                disabled: true
                            }}
                            label={
                                <Typography className={classes.inputLabel}>
                                    Due Date
                                </Typography>
                            }
                            value={details['details']['dueDate']}
                        ></TextField> 
                        : ''
                    }
                </>
            )}
        </>
    )
}

export default PhilhealthNewDetails

